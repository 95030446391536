import "./style.scss";
import { IonAlert, IonButton, IonCol, IonGrid, IonRow, IonToggle } from "@ionic/react";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { payout, updatePaymentSchedule } from "./api";
import { ClipboardPaySchedule, PayrollSettingsDropdownProps } from "./models";
import { MessageString } from "../../constants/strings";
import { USER_EVENTS } from "../../constants/userEvents";
import { formatDollarsAsUsd } from "../../utils/currency";
import { updateAgentProfileState } from "../onboardingStripe/actions";
import { useDefinedAgent } from "../store/helperHooks";

const AutomaticPaySchedules: readonly ClipboardPaySchedule[] = [
  "instantly",
  "daily",
  "weekly",
] as const;

const IntervalText: Record<Exclude<ClipboardPaySchedule, "manual">, string> = {
  instantly: "Instantly withdraw",
  daily: "At the end of the day",
  weekly: "Every Thursday",
};

const PayrollSettingsDropdown: FC<PayrollSettingsDropdownProps> = ({
  balance,
  currentPaySchedule,
  onPayScheduleChange,
}) => {
  const dispatch = useDispatch();
  const agent = useDefinedAgent();
  const isCurrentPayScheduleAutomatic = currentPaySchedule !== "manual";
  const [loading, setLoading] = useState(false);
  const { showErrorToast } = useToast();

  const [modalSettings, setModalSettings] = useState<
    Partial<{
      isOpen: boolean;
      message: string;
      title: string;
      confirmTextButton: string;
      confirmFunction: () => void;
      cancelFunction: () => void;
    }>
  >();
  const dismissModal = useCallback(() => {
    setModalSettings((settings) => ({ ...settings, isOpen: false }));
  }, []);

  const submitPaymentInterval = async (interval: "manual" | "weekly" | "daily" | "instantly") => {
    try {
      setLoading(true);
      const paymentAccountInfoUpdated = await updatePaymentSchedule({
        interval,
      });
      dispatch(
        updateAgentProfileState({
          paymentAccountInfo: paymentAccountInfoUpdated,
        })
      );
      onPayScheduleChange(interval);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showErrorToast("Failed to save the new pay settings.");
      console.error(error);
    }
  };

  const paymentMethod = balance?.hasDebitCard ? "card" : "bank account";

  const handleAutomaticPaymentToggle = () => {
    if (isCurrentPayScheduleAutomatic) {
      setModalSettings({
        title: "Turn off automatic payouts?",
        message: "This means you won’t receive any payouts unless you manually withdraw your funds",
        isOpen: true,
        cancelFunction: () => {
          dismissModal();
        },
        confirmFunction: async () => {
          dismissModal();
          logEvent(USER_EVENTS.AUTO_WITHDRAW_DISABLED);
          await submitPaymentInterval("manual");
        },
        confirmTextButton: "Turn it Off",
      });
    } else {
      logEvent(USER_EVENTS.AUTO_WITHDRAW_REQUESTED);
      setModalSettings({
        title: "Turn on Auto-Withdraw?",
        message: `After each transfer, we will immediately sent to your ${paymentMethod}
        and we will remove ${formatDollarsAsUsd(
          agent?.paymentAccountInfo?.withdrawalFee as number
        )} to cover processing costs.`,
        isOpen: true,
        cancelFunction: () => {
          dismissModal();
          logEvent(USER_EVENTS.AUTO_WITHDRAW_REJECTED);
        },
        confirmFunction: async () => {
          dismissModal();
          logEvent(USER_EVENTS.AUTO_WITHDRAW_CONFIRMED);
          await submitPaymentInterval("instantly");
          try {
            await payout();
          } catch (error) {
            const defaultMessage = MessageString.payrollSummary.payoutFailedForUnknownReason;
            const message =
              error.status === 400
                ? error.response?.body?.message ?? defaultMessage
                : defaultMessage;
            void showErrorToast(message);
          }
        },
        confirmTextButton: "Turn it On",
      });
    }
  };

  const handleSelectedOption = (option: ClipboardPaySchedule) => {
    if (option === currentPaySchedule) {
      return;
    }
    if (option === "instantly") {
      logEvent(USER_EVENTS.AUTO_WITHDRAW_REQUESTED);
      setModalSettings({
        title: "Turn on Auto-Withdraw?",
        message: `After each transfer, we will immediately sent to your ${paymentMethod}
          and we will remove ${formatDollarsAsUsd(
            agent?.paymentAccountInfo?.withdrawalFee as number
          )} to cover processing costs.`,
        isOpen: true,
        cancelFunction: () => {
          logEvent(USER_EVENTS.AUTO_WITHDRAW_REJECTED);
          dismissModal();
        },
        confirmFunction: async () => {
          dismissModal();
          await submitPaymentInterval("instantly");
          logEvent(USER_EVENTS.AUTO_WITHDRAW_CONFIRMED);
          try {
            await payout();
          } catch (error) {
            const defaultMessage = MessageString.payrollSummary.payoutFailedForUnknownReason;
            const message =
              error.status === 400
                ? error.response?.body?.message ?? defaultMessage
                : defaultMessage;
            showErrorToast(message);
          }
        },
        confirmTextButton: "Turn it On",
      });
    } else if (option === "daily") {
      setModalSettings({
        title: "Turn on Daily Payouts?",
        message: `If there’s balance in your account at the end of the day,
          Stripe will send funds for free to your ${paymentMethod} -- it will take 2-3 business days.`,
        isOpen: true,
        cancelFunction: () => {
          dismissModal();
        },
        confirmFunction: async () => {
          dismissModal();
          await submitPaymentInterval("daily");
          logEvent(USER_EVENTS.AUTO_WITHDRAW_DISABLED);
        },
        confirmTextButton: "Turn it On",
      });
    } else if (option === "weekly") {
      setModalSettings({
        title: "Turn on Weekly Payouts?",
        message: `Once a week on Thursday, Stripe will check your account
          to see if there’s a balance in your account. If there is,
          funds will reach your ${paymentMethod} for free on the following Monday`,
        isOpen: true,
        cancelFunction: () => {
          dismissModal();
        },
        confirmFunction: async () => {
          dismissModal();
          await submitPaymentInterval("weekly");
          logEvent(USER_EVENTS.AUTO_WITHDRAW_DISABLED);
        },
        confirmTextButton: "Turn it On",
      });
    }
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol
            offset="0"
            size={balance?.hasDebitCard ? "8" : "12"}
            style={{ textAlign: "center" }}
          >
            Stripe sends money to my account automatically
          </IonCol>
          {balance?.hasDebitCard && (
            <IonCol size="4">
              {/* Using onClick instead of onIonChange as onIonChange triggers on changes to the "checked" prop */}
              <IonToggle
                disabled={loading}
                color="primary"
                checked={isCurrentPayScheduleAutomatic}
                onClick={handleAutomaticPaymentToggle}
                aria-label={`Turn ${
                  isCurrentPayScheduleAutomatic ? "off" : "on"
                } automatic payments`}
              />
            </IonCol>
          )}
        </IonRow>
        <hr style={{ height: 1, marginBottom: 5 }}></hr>
        {isCurrentPayScheduleAutomatic && (
          <IonRow style={{ padding: 0, margin: "10px 0px" }}>
            {AutomaticPaySchedules.map((schedule) => {
              if (schedule === "instantly" && !balance.hasDebitCard) {
                return null;
              }
              const isSelected = schedule === currentPaySchedule;
              return (
                <IonCol
                  key={schedule}
                  size={"4"}
                  className={`payroll-settings-automatic-option ${
                    isSelected ? "selected-option" : ""
                  }`}
                >
                  <IonButton
                    fill="default"
                    expand="block"
                    className="ion-text-wrap"
                    disabled={loading}
                    onClick={() => handleSelectedOption(schedule)}
                    aria-selected={isSelected}
                    /**
                     * `aria-selected` is the accessible attribute to indicate that something is selected.
                     * `aria-label` is not really valid here. The label should indicate what would happen
                     * if a user clicked this button.
                     */
                    aria-label={`${IntervalText[schedule]}${isSelected ? " selected" : ""}`}
                  >
                    {IntervalText[schedule]}
                  </IonButton>
                </IonCol>
              );
            })}
          </IonRow>
        )}
      </IonGrid>
      <IonAlert
        isOpen={modalSettings?.isOpen as boolean}
        onDidDismiss={dismissModal}
        header={modalSettings?.title}
        message={modalSettings?.message}
        backdropDismiss={true}
        /**
         * This animation sometimes makes the UI unresponsive, blocking
         * button clicks on the underlying page.
         * Disabling animation removes the issue. (observed in e2e tests)
         */
        animated={false}
        buttons={[
          {
            text: "Never Mind",
            role: "cancel",
            handler: modalSettings?.cancelFunction,
          },
          {
            text: modalSettings?.confirmTextButton as string,
            handler: modalSettings?.confirmFunction,
          },
        ]}
      />
    </>
  );
};

export { PayrollSettingsDropdown };
